import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";
// GeneralViews
import NotFound from "@/pages/NotFoundPage.vue";
import { isAdmin, isUser } from "../router/middleware";
// Admin pages
const Dashboard = () =>
  import(/* webpackChunkName: "dashboard" */ "@/pages/Dashboard.vue");
const LoginPage = () =>
  import(/* webpackChunkName: "common" */ "@/pages/Login/Login.vue");
const Users = () =>
  import(/* webpackChunkName: "common" */ "@/pages/Admin/Users/UserList.vue");
const Projects = () =>
  import(
    /* webpackChunkName: "common" */ "@/pages/Admin/Projects/Projects.vue"
  );
const Buildings = () =>
  import(
    /* webpackChunkName: "common" */ "@/pages/Admin/Building/Building.vue"
  );
const Devices = () =>
  import(
    /* webpackChunkName: "common" */ "@/pages/Admin/DeviceConnected/Devices.vue"
  );
const DeviceType = () =>
  import(
    /* webpackChunkName: "common" */ "@/pages/Admin/DeviceType/Devices.vue"
  );

const routes = [
  {
    path: "/",
    component: DashboardLayout,
    redirect: "/dashboard",
    meta: { requiresAuth: true },
    beforeEnter: isAdmin,
    children: [
      {
        path: "dashboard",
        name: "dashboard",
        component: Dashboard,
        meta: { requiresAuth: true },
      },
      {
        path: "/users",
        meta: { requiresAuth: true },
        component: Users,
      },
      {
        path: "/projects",
        meta: { requiresAuth: true },
        component: Projects,
      },
      {
        path: "/buildings",
        meta: { requiresAuth: true },
        component: Buildings,
      },
      {
        path: "/devices",
        meta: { requiresAuth: true },
        component: Devices,
      },
      {
        path: "/device-type",
        meta: { requiresAuth: true },
        component: DeviceType,
      },
    ],
  },
  { path: "*", component: NotFound },
  {
    path: "/login",
    component: LoginPage,
  },
];

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

export default routes;
