<template>
  <div class="wrapper">
    <side-bar>
      <template slot="links">
        <sidebar-link
          to="/dashboard"
          :name="$t('sidebar.dashboard')"
          icon="bi bi-house-door-fill"
        />
        <sidebar-link
          to="/projects"
          :name="$t('sidebar.projects')"
          icon="bi bi-kanban"
        />
        <sidebar-link
          to="/buildings"
          :name="$t('sidebar.buildings')"
          icon="bi bi-kanban"
        />
        <sidebar-link
          to="/device-type"
          :name="$t('sidebar.device_type')"
          icon="bi bi-device-ssd-fill"
        />
        <sidebar-link
          to="/devices"
          :name="$t('sidebar.DeviceConnected')"
          icon="bi bi-device-ssd-fill"
        />
        <sidebar-link
          to="/users"
          :name="$t('sidebar.users')"
          icon="bi bi-people-fill"
        />
        <sidebar-link
          to="/login"
          :name="$t('sidebar.logout')"
          icon="bi bi-box-arrow-right"

        />
      </template>
    </side-bar>
    <div class="main-panel">
      <div class="content">
        <fade-transition :duration="100" mode="out-in">
          <router-view></router-view>
        </fade-transition>
      </div>
      <!-- <content-footer></content-footer> -->
    </div>
  </div>
</template>
<style lang="scss"></style>
<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "./MobileMenu";
import { FadeTransition } from "vue2-transitions";
export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu,
    FadeTransition,
  },
  methods: {
    // toggleSidebar() {
    //   if (this.$sidebar.showSidebar) {
    //     this.$sidebar.displaySidebar(false);
    //   }
    // },
    async logout() {
      try {
        // const response = await axios.get("https://admin.lotti.webmarken.com/api/auth/logout");
        localStorage.removeItem("token");
        this.$router.push("/login");
      } catch (error) {
        console.log(error);
        // Handle error cases, e.g., show an error message to the user
        alert("Login failed. Please try again.");
      }
    },
  },
};
</script>

<style>
.sidebar{
  margin-top: 0px !important;
  margin-left: 0px !important;
  height: inherit  !important;
}
.main-panel > .content{
  padding: 10px 30px 30px 280px !important;
}
.sidebar .nav li > a, .off-canvas-sidebar .nav li > a{

font-size: 13px !important;
}

.nav-link:hover{
  background: #1e1e29 !important;
}
</style>
